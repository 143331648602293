import { ProductTypeRule } from "@/interfaces/products/responses/ProductTypeRule";
import axios from "axios";
import { defineStore } from "pinia";

export const useProductTypeRulesStore = defineStore('product_type_rules', {
  state: () => ({
    rules: [] as ProductTypeRule[],
  }),
  getters: {
    getRules: (state): ProductTypeRule[] => state.rules,
  },
  actions: {
    async fetchRules(): Promise<void> {
      const response = await axios.get('api/product_type_rules');
      this.$state.rules = response.data.data;
    },

    async fetchRulesByProductTypeId(productTypeId: number): Promise<void> {
      const response = await axios.get('api/product_type_rules');
      this.$state.rules = response.data.data.filter((rule: ProductTypeRule) => {
        return rule.product_type.id === productTypeId;
      });
    },
  }
});
