import {defineStore} from "pinia";
import axios from "axios";

export const useDepartmentsStore = defineStore('departments', {
    state: () => ({
        departments: [],
    }),
    getters: {
        getDepartments: (state) => state.departments,
    },
    actions: {
        async fetchDepartments() {
            const response = await axios.get('api/user_departments');
            this.$state.departments = response.data.data;
        }
    },
});