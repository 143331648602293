import axios from "axios";
import { defineStore } from "pinia";

export const useProductTypeStore = defineStore('productType', {
  state: () => ({
    productType: null,
  }),
  getters: {
    getProductType: (state) => state.productType,
  },
  actions: {
    async fetchProductType(id: string) {
      const response = await axios.get('api/product_types/' + id);
      this.$state.productType = response.data.data;
    }
  },
});
