import {defineStore} from "pinia";
import axios from "axios";

export const useProductCategoriesStore = defineStore('productCategories', {
    state: () => ({
        productCategories: [],
    }),
    getters: {
        getProductCategories: (state) => state.productCategories,
    },
    actions: {
        async fetchProductCategories() {
            const response = await axios.get('api/product_categories');
            this.$state.productCategories = response.data.data;
        },
    },
});