import axios from "axios";
import { defineStore } from "pinia";

export const useProductStore = defineStore('product', {
  state: () => ({
    product: null,
  }),
  getters: {
    getProduct: (state) => state.product,
  },
  actions: {
    async fetchProduct(id: string) {
      const response = await axios.get(
        'api/products/' + id,
      );
      this.$state.product = response.data.data;
    },
  },
});
