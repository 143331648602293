import { ProductRule } from "@/interfaces/products/responses/ProductRule";
import axios from "axios";
import { defineStore } from "pinia";

export const useProductRulesStore = defineStore('product_rules', {
    state: () => ({
        rules: [] as ProductRule[],
    }),
    getters: {
        getRules: (state): ProductRule[] => state.rules,
    },
    actions: {
        async fetchRules(): Promise<void> {
            const response = await axios.get('api/product_rules');
            this.$state.rules = response.data.data;
        },

        async fetchRulesByProductId(productId: number): Promise<void> {
            const response = await axios.get('api/product_rules');
            this.$state.rules = response.data.data.filter((rule: ProductRule) => {
                return rule.product.id === productId;
            });
        },
    }
});
