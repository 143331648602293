import { defineStore } from "pinia";

export const useLoadingStore = defineStore('loading', {
    state: () => ({
        requestsPending: 0,
        loading: false,
        // persistent loading exceptions 
        // url has to be a valid regex
        exceptions: [
            {
                url: '\\/?api\\/order_items\\/[0-9]+\\/status',
                method: 'patch'
            },
            {
                url: '\\/?api\\/order_items\\/[0-9]+\\/delivery_date',
                method: 'patch'
            },
        ],
    }),
    getters: {
        isLoading: (state) => state.loading,
        getExceptions: (state) => state.exceptions,
    },
    actions: {
        addException(exception: { url: string, method: string }): void {
            this.$state.exceptions.push(exception);
        },
        deleteException(exception: { url: string, method: string }): void {
            this.$state.exceptions = this.$state.exceptions.filter((ex) => {
                return ex.method !== exception.method || ex.url !== exception.url;
            });
        },
        show() {
            this.$state.loading = true;
        },
        hide() {
            this.$state.loading = false;
        },
        pending() {
            if (this.$state.requestsPending === 0) {
                this.show();
            }

            this.$state.requestsPending++;
        },
        done() {
            if (this.$state.requestsPending >= 1) {
                this.$state.requestsPending--;
            }

            if (this.$state.requestsPending <= 0) {
                this.hide();
            }
        },
    },
});
