import { defineStore } from "pinia";
import axios from "axios";
import { ProductType } from "@/interfaces/products/responses/ProductType";

export const useProductTypesStore = defineStore('productTypes', {
    state: () => ({
        productTypes: [] as ProductType[],
    }),
    getters: {
        getProductTypes: (state) => state.productTypes,
    },
    actions: {
        async fetchProductTypes() {
            const response = await axios.get('api/product_types');
            this.$state.productTypes = response.data.data;
        },

        removeItem(item: ProductType): void | null {
            const index = this.$state.productTypes.findIndex((i: ProductType) => i.id === item.id);
            if (index !== -1) {
                this.$state.productTypes.splice(index, 1);
            }

            return null;
        },
    },
});
