import { OrderStatus } from "@/enums/OrderStatus";
import { Order } from "./interfaces/orders/response/Order";
import { Gender } from "@/enums/Gender";
import { ProductRuleOption } from "./enums/ProductRuleOption";
import { ProductTypeRuleOption } from "./enums/ProductTypeRuleOption";
import { OrderItemStatus } from "./enums/OrderItemStatus";

export const formatDate = (date: Date): string => {
    return date.toLocaleDateString("de-DE");
};

export const getOrderStatusTagSeverity = (status: OrderStatus): string => {
    switch (status) {
        case OrderStatus.OPEN:
            return 'info';
        case OrderStatus.IN_PROGRESS:
            return 'warning';
        case OrderStatus.DONE:
            return 'success';
    }
};

export const getOrderItemStatusTagSeverity = (status: OrderItemStatus): string => {
    switch (status) {
        case OrderItemStatus.ORDERED:
            return 'info';
        case OrderItemStatus.DELIVERY_DELAY:
            return 'warning';
        case OrderItemStatus.NOT_AVAILABLE:
            return 'danger';
        case OrderItemStatus.SUBSTITUTE:
            return 'warning';
        case OrderItemStatus.MISSING:
            return 'danger';
        case OrderItemStatus.IN_PRINT:
            return 'primary';
        case OrderItemStatus.DELIVERED:
            return 'success';
        case OrderItemStatus.READY_TO_OUTPUT:
            return 'success';
        case OrderItemStatus.DONE:
            return 'success';
        case OrderItemStatus.OPEN:
            return 'info';
    }
};

export const mapOrderStatus = (status: OrderStatus): string => {
    switch (status) {
        case OrderStatus.OPEN:
            return 'Offen';
        case OrderStatus.IN_PROGRESS:
            return 'In Bearbeitung';
        case OrderStatus.DONE:
            return 'Erledigt';
    }
};

export const mapOrderItemStatus = (status: OrderItemStatus): string => {
    switch (status) {
        case OrderItemStatus.ORDERED:
            return 'Bestellt';
        case OrderItemStatus.DELIVERY_DELAY:
            return 'Lieferverzögerung';
        case OrderItemStatus.NOT_AVAILABLE:
            return 'Nicht lieferbar';
        case OrderItemStatus.SUBSTITUTE:
            return 'Rückgabe/Ersatz';
        case OrderItemStatus.MISSING:
            return 'Fehlt';
        case OrderItemStatus.IN_PRINT:
            return 'In Druckerei';
        case OrderItemStatus.DELIVERED:
            return 'Geliefert';
        case OrderItemStatus.READY_TO_OUTPUT:
            return 'Bereit zur Ausgabe';
        case OrderItemStatus.DONE:
            return 'Erledigt';
        case OrderItemStatus.OPEN:
            return 'Offen';
    }
};

export const getGenderDisplay = (gender: Gender): string => {
    switch (gender) {
        case Gender.MALE:
            return 'Herren';
        case Gender.FEMALE:
            return 'Damen';
        case Gender.NONBINARY:
            return 'Non-Binär';
    }
};

export const getProductRuleOptionDisplay = (ruleOption: ProductRuleOption): string => {
    switch (ruleOption) {
        case ProductRuleOption.MAX_AMOUNT:
            return 'Maximale Anzahl';
        case ProductRuleOption.ORDER_INTERVAL:
            return 'Bestellintervall';
        case ProductRuleOption.TEAMLEADERS_ONLY:
            return 'Nur für Teamleiter';
    }
};

export const getProductTypeRuleOptionDisplay = (ruleOption: ProductTypeRuleOption): string => {
    switch (ruleOption) {
        case ProductTypeRuleOption.MAX_AMOUNT:
            return 'Maximale Anzahl';
        case ProductTypeRuleOption.ORDER_INTERVAL:
            return 'Bestellintervall';
        case ProductTypeRuleOption.TEAMLEADERS_ONLY:
            return 'Nur für Teamleiter';
    }
};

export const mapDate = (data: Array<Order>): Array<Order> => {
    return data.map((order: Order) => {
        order.created_at = new Date(order.created_at);
        return order;
    });
};

export const getAttributeByString = (object: object, attribute: string): any | null => {
    let value = object;
    for (const attr of attribute.split('.')) {
        value = value[attr as keyof typeof object];
        if (!value) {
            return null;
        }
    }
    return value;
};
