import {defineStore} from "pinia";
import axios from "axios";
import {Product} from "@/interfaces/products/responses/Product";

export const useProductsStore = defineStore('products', {
    state: () => ({
        products: [],
    }),
    getters: {
        getProducts: (state) => state.products,
    },
    actions: {
        async fetchProducts() {
            const response = await axios.get('api/products');
            this.$state.products = response.data.data;
        },

        async fetchProduct(id: string | string[]) : Promise<Product> {
            const response = await axios.get(
                'api/products/' + id,
            );
            return response.data.data;
        },

        removeItem(item: Product): void|null {
            const index = this.$state.products.findIndex((i: Product) => i.id === item.id);
            if(index !== -1) {
                this.$state.products.splice(index, 1);
            }

            return null;
        },

    },
});