import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import AccountView from '../views/user/AccountView.vue';
import ShopView from '../views/user/ShopView.vue';
import NotFoundView from "@/views/NotFoundView.vue";
import CartView from "@/views/user/CartView.vue";
import OrderView from "@/views/user/OrderView.vue";
import ProductsView from "@/views/admin/products/ProductsView.vue";
import UsersView from "@/views/admin/users/UsersView.vue";
import PasswordChangeView from "@/views/user/PasswordChangeView.vue";
import OrdersView from "@/views/admin/orders/OrdersView.vue";
import UserEditView from "@/views/admin/users/UserEditView.vue";
import UserAddView from "@/views/admin/users/UserAddView.vue";
import ProductEditView from "@/views/admin/products/ProductEditView.vue";
import ProductAddView from "@/views/admin/products/ProductAddView.vue";
import OrderEditView from "@/views/admin/orders/OrderEditView.vue";
import OrderAddView from "@/views/admin/orders/OrderAddView.vue";
import ProductTypesView from "@/views/admin/product_types/ProductTypesView.vue";
import ProductTypeAddView from "@/views/admin/product_types/ProductTypeAddView.vue";
import ProductTypeEditView from "@/views/admin/product_types/ProductTypeEditView.vue";
import { useAccountStore } from "@/store/account-store";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    alias: '/',
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/account/:id',
    name: 'account',
    component: AccountView,
    meta: {
      requiresAuth: true,
      title: 'Account',
    }
  },
  {
    path: '/account/:id/orders',
    name: 'myorders',
    component: OrderView,
    meta: {
      requiresAuth: true,
      title: 'Bestellungen',
    }
  },
  {
    path: '/account/password-change',
    name: 'passwordChange',
    component: PasswordChangeView,
    meta: {
      requiresAuth: true,
      title: 'Passwort ändern',
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundView,
    meta: {
      title: '404',
    }
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true, // temp fix if orders are disabled
      title: 'Shop',
    },
  },
  {
    path: '/admin/products',
    name: 'products',
    component: ProductsView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: 'Admin - Produkte'
    },
  },
  {
    path: '/admin/users',
    name: 'users',
    component: UsersView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: 'Admin - Nutzer*innen'
    }
  },
  {
    path: '/admin/orders',
    name: 'orders',
    component: OrdersView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: 'Admin - Bestellungen'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true, // temp fix if orders are disabled
      title: 'Einkaufswagen',
    },
  },
  {
    path: '/user/edit/:id',
    name: 'userEdit',
    component: UserEditView,
    meta: {
      requiresAuth: true,
      title: 'Nutzerkonto - Bearbeiten',
    },
    props: true,
  },
  {
    path: '/user/add',
    name: 'userAdd',
    component: UserAddView,
    meta: {
      requiresAuth: true,
      title: 'Nutzerkonto - Erstellen',
    },
  },
  {
    path: '/product/edit/:id',
    name: 'productEdit',
    component: ProductEditView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: 'Produkt - Bearbeiten',
    },
    props: true,
  },
  {
    path: '/product/add',
    name: 'productAdd',
    component: ProductAddView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: 'Produkt - Erstellen',
    },
  },
  {
    path: '/order/edit/:id',
    name: 'orderEdit',
    component: OrderEditView,
    meta: {
      requiresAuth: true,
      title: 'Bestellung - Bearbeiten',
    },
    props: true,
  },
  {
    path: '/order/add',
    name: 'orderAdd',
    component: OrderAddView,
    meta: {
      requiresAuth: true,
      title: 'Bestellung - Hinzufügen',
    },
    props: true,
  },
  {
    path: '/admin/product-types',
    name: 'productTypes',
    component: ProductTypesView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: 'Admin - Produkttypen',
    },
  },
  {
    path: '/admin/product-type/add',
    name: 'productTypeAdd',
    component: ProductTypeAddView,
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      title: 'Produkttyp - Hinzufügen',
    },
  },
  {
    path: '/admin/product-type/edit/:id',
    name: 'productTypeEdit',
    component: ProductTypeEditView,
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      title: 'Produkttyp - Hinzufügen',
    },
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});
router.beforeEach((to, _from, next) => {
  document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`;
  const accountStore = useAccountStore();
  const userIsAuthenticated = accountStore.isLoggedIn;
  const userIsAdmin = accountStore.hasRole('Admin');
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!userIsAdmin) {
      if(userIsAuthenticated) {
        router.push('/account/' + accountStore.getUser?.id);
      } else {
        next({
          path: "/login",
          query: { redirect: to.fullPath }
        });
      }
    }
    else {
      next();
    }
  } else if(to.matched.some((record) => record.meta.requiresAuth)) {
    if (!userIsAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    }
    else {
      next();
    }
  } else {
    next();
  }
});


export default router;
