import {defineStore} from "pinia";
import axios from "axios";

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
    }),
    getters: {
        getUser: (state) => state.user,
    },
    actions: {
        async fetchUser(id: string | string[]) {
            const response = await axios.get(
                'api/users/' + id,
            );
            this.$state.user = response.data.data;
        },
    },
});