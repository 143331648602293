import { OrderItemStatus } from '@/enums/OrderItemStatus';
import { ProductRuleOption } from '@/enums/ProductRuleOption';
import { ProductTypeRuleOption } from '@/enums/ProductTypeRuleOption';
import * as yup from 'yup';
import { de } from 'yup-locales';

yup.setLocale(de);

export const loginFormSchema = yup.object({
  email: yup.string().required().email().label("Email"),
  password: yup.string().required().label("Passwort"),
});

export const userFormSchema = yup.object({
  first_name: yup.string().required().label("Vorname"),
  last_name: yup.string().required().label("Nachname"),
  email: yup.string().required().email().label("Email"),
  department: yup.object().required().label("Abteilung"),
  roles: yup.array().required().min(1).label("Rollen"),
  active: yup.bool().required().label("Aktiv"),
});

export const productFormSchema = yup.object({
  name: yup.string().required().label("Name"),
  product_number: yup.string().required().label("Produktnummer"),
  supplier: yup.object().required().label("Lieferant"),
  image_path: yup.string().optional().label("Bild"),
  shop_link: yup.string().url().nullable().label("Shop Link"),
  active: yup.bool().required().label("Aktiv"),
  type: yup.object().required().label("Typ"),
  category: yup.object().required().label("Kategorie"),
  sizes: yup.array().required().label("Größen"),
  departments: yup.array().required().min(1).label("Abteilungen"),
});

export const orderFormSchema = yup.object({
  user: yup.object().required().label("Nutzer"),
  order_items: yup.array().required().min(1).label("Produkte"),
});

export const orderItemFormSchema = yup.object({
  product: yup.object().required().label("Produkt"),
  status: yup.string().required().oneOf(Object.values(OrderItemStatus)).label("Status"),
  amount: yup.number().required().min(1).label("Anzahl"),
  delivery_date: yup.date().when('status', {
    is: OrderItemStatus.DONE,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }).label("Ausgabedatum"),
});

export const productRuleFormSchema = yup.object({
  product: yup.object().required().label("Produkt"),
  user_department: yup.object().required().label("Abteilung"),
  rule_option: yup.string().required().oneOf(Object.values(ProductRuleOption)).label("Regel"),
  value: yup.number().when('rule_option', {
    is: ProductRuleOption.TEAMLEADERS_ONLY,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required(),
  }).label("Wert"),
});

export const productTypeRuleFormSchema = yup.object({
  product_type: yup.object().required().label("Produkttyp"),
  user_department: yup.object().required().label("Abteilung"),
  rule_option: yup.string().required().oneOf(Object.values(ProductTypeRuleOption)).label("Regel"),
  value: yup.number().when('rule_option', {
    is: ProductTypeRuleOption.TEAMLEADERS_ONLY,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required(),
  }).label("Wert"),
});

export const productTypeFormSchema = yup.object({
  name: yup.string().required().label("Name"),
  product_category: yup.object().required().label("Kategorie"),
  product_sizes: yup.array().required().label("Größen"),
});
