import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from "axios-auth-refresh";
import router from "@/router";
import { useLoadingStore } from "@/store/loading-store";

/* eslint-disable */
const userStorage = window.localStorage.getItem('account');
const user = userStorage ? JSON.parse(userStorage) : null;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
if (user) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
}

// if statusCode is 419 from sanctum just refresh the csrf-cookie before the next request is starting
const refreshAuthLogic = () => axios.get('/sanctum/csrf-cookie').then(() => Promise.resolve());

createAuthRefreshInterceptor(axios, refreshAuthLogic, { statusCodes: [419] });

const requestHandler = (request: InternalAxiosRequestConfig<any>) => {
    const loadingStore = useLoadingStore();
    for (const e of loadingStore.getExceptions) {
        const regex = new RegExp(e.url);
        const result = regex.exec(request.url!);
        if (result && e.method === request.method) {
            return request;
        }
    }
    loadingStore.pending();
    return request;
};
const responseHandler = (response: AxiosResponse<any, any>) => {
    const loadingStore = useLoadingStore();
    loadingStore.done();
    if (response.status === 401) {
        router.push({ path: '/login' });
    }

    return response;
};

const errorHandler = (error: any) => {
    const loadingStore = useLoadingStore();
    loadingStore.done();
    return Promise.reject(error);
};

axios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

axios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

