import { defineStore } from "pinia";
import axios, { AxiosResponse } from "axios";
import { User } from "@/interfaces/users/responses/User";

export const useAccountStore = defineStore('account', {
    state: () => ({
        user: null as User | null,
        token: null,
    }),
    getters: {
        isLoggedIn: (state) => !!state.user,
        getUser: (state): User | null => state.user,
    },
    actions: {
        setUserDetails(response: AxiosResponse) {
            this.$state.user = response.data.user;
            this.$state.token = response.data.token;
        },

        async fetchUser(id: string | string[]) {
            const response = await axios.get(
                'api/users/' + id,
            );
            this.$state.user = response.data.data;
        },

        hasPermission(permissionCode: string): boolean {

            const roles = this.$state.user?.roles;

            if (typeof roles === 'undefined') {
                return false;
            }

            for (const role of roles) {
                for (const permission of role.permissions) {
                    if (permission.code === permissionCode) {
                        return true;
                    }
                }
            }
            return false;
        },
        hasAnyViewPermission(): boolean {
            return this.hasPermission('users.view')
                || this.hasPermission('products.view')
                || this.hasPermission('orders.all.view');
        },

        hasRole(roleName: string): boolean {
            const roles = this.$state.user?.roles;

            if (typeof roles === 'undefined') {
                return false;
            }

            for (const role of roles) {
                if(role.name === roleName) {
                    return true;
                }
            }
            return false;
        }
    },
    persist: true,
});
