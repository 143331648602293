import { FilterMatchMode } from "primevue/api";

export const numberFilterMatchOptions = [
    { label: 'Ist', value: FilterMatchMode.EQUALS },
    { label: 'Mehr als', value: FilterMatchMode.GREATER_THAN },
    { label: 'Weniger als', value: FilterMatchMode.LESS_THAN },
    { label: 'Ist nicht', value: FilterMatchMode.NOT_EQUALS },
];

export const stringFilterMatchOptions = [
    { label: 'Enthält', value: FilterMatchMode.CONTAINS },
    { label: 'Enthält nicht', value: FilterMatchMode.NOT_CONTAINS },
];

export const selectionFilterMatchOptions = [
    { label: 'In', value: FilterMatchMode.IN },
];

export const dateFilterMatchOptions = [
    { label: 'Datum ist', value: FilterMatchMode.DATE_IS },
    { label: 'Datum nach', value: FilterMatchMode.DATE_AFTER },
    { label: 'Datum vor', value: FilterMatchMode.DATE_BEFORE },
    { label: 'Datum ist nicht', value: FilterMatchMode.DATE_IS_NOT },
];
