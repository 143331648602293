import {defineStore} from "pinia";
import axios from "axios";
import {User} from "@/interfaces/users/responses/User";

export const useUsersStore = defineStore('users', {
    state: () => ({
        users: [],
    }),
    getters: {
        getUsers: (state) => state.users,
    },
    actions: {
        async fetchUsers() {
            const response = await axios.get('api/users');
            this.$state.users = response.data.data;
        },

        removeUser(user: User): void|null {
            const index = this.$state.users.findIndex((i: User) => i.id === user.id);
            if (index !== -1) {
                this.$state.users.splice(index, 1);
            }
            return null;
        },
    },
});