import axios from "axios";
import { defineStore } from "pinia";

export const useSuppliersStore = defineStore('suppliers', {
    state: () => ({
        suppliers: [],
    }),
    getters: {
        getSuppliers: (state) => state.suppliers,
    },
    actions: {
        async fetchSuppliers() {
            const response = await axios.get('api/suppliers');
            this.$state.suppliers = response.data.data;
        }
    },
});
