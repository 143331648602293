import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './axios.ts';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Card from "primevue/card";
import Password from "primevue/password";
import PanelMenu from "primevue/panelmenu";
import ConfirmDialog from "primevue/confirmdialog";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import Tag from "primevue/tag";
import DataView from "primevue/dataview";
import InputNumber from "primevue/inputnumber";
import Toast from "primevue/toast";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Slider from "primevue/slider";
import Calendar from 'primevue/calendar';
import MultiSelect from "primevue/multiselect";
import ToastService from "primevue/toastservice";
import ConfirmationService from 'primevue/confirmationservice';
import InputSwitch from 'primevue/inputswitch';
import Message from 'primevue/message';
import Skeleton from 'primevue/skeleton';
import DynamicDialog from 'primevue/dynamicdialog';
import DialogService from 'primevue/dialogservice';
import Divider from 'primevue/divider';
import Textarea from "primevue/textarea";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Tooltip from 'primevue/tooltip';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import FloatLabel from 'primevue/floatlabel';
import './assets/themes/emondo-theme/theme.scss';
import './index.css';
import '/node_modules/primeflex/primeflex.css';
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const pinia = createPinia().use(piniaPluginPersistedstate);

const app = createApp(App)
    .use(router)
    .use(PrimeVue, {
        locale: {
            weak: "schwach",
            medium: "mittel",
            strong: "stark",
            passwordPrompt: "Gebe ein Passwort ein",
            yes: "ja",
            no: "nein",
            clear: "löschen",
            apply: "übernehmen",
            today: "Heute",
            monthNames: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
            ],
            monthNamesShort: [
                'Jan',
                'Feb',
                'Mär',
                'Apr',
                'Mai',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Okt',
                'Nov',
                'Dez',
            ],
            dayNames: [
                'Montag',
                'Dienstag',
                'Mittwoch',
                'Donnerstag',
                'Freitag',
                'Samstag',
                'Sonntag',
            ],
            dayNamesMin: [
                'Mo',
                'Di',
                'Mi',
                'Do',
                'Fr',
                'Sa',
                'So',
            ],
            aria: {
                trueLabel: 'wahr',
                falseLabel: 'falsch',
                nullLabel: 'null',
                pageLabel: 'page',
            }
        },
        ripple: true,
    })
    .use(ConfirmationService)
    .use(ToastService)
    .use(DialogService)
    .use(pinia);

app.mount('#app');

app.component('PrimeInputText', InputText);
app.component('PrimeButton', Button);
app.component('PrimeCard', Card);
app.component('PrimePassword', Password);
app.component('PrimePanelMenu', PanelMenu);
app.component('PrimeConfirmDialog', ConfirmDialog);
app.component('PrimeTag', Tag);
app.component('PrimeDropDown', Dropdown);
app.component('PrimeSelectButton', SelectButton);
app.component('PrimeDataView', DataView);
app.component('PrimeInputNumber', InputNumber);
app.component('PrimeToast', Toast);
app.component('PrimeDataTable', DataTable);
app.component('PrimeColumn', Column);
app.component('PrimeSlider', Slider);
app.component('PrimeCalendar', Calendar);
app.component('PrimeMultiSelect', MultiSelect);
app.component('PrimeInputSwitch', InputSwitch);
app.component('PrimeMessage', Message);
app.component('PrimeSkeleton', Skeleton);
app.component('PrimeDynamicDialog', DynamicDialog);
app.component('PrimeDivider', Divider);
app.component('PrimeTextArea', Textarea);
app.component('PrimeAccordion', Accordion);
app.component('PrimeAccordionTab', AccordionTab);
app.component('PrimeIconField', IconField);
app.component('PrimeInputIcon', InputIcon);
app.component('PrimeFloatLabel', FloatLabel);

app.directive('tooltip', Tooltip);
