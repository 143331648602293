import { defineStore } from "pinia";
import { CartItem } from "@/interfaces/CartItem";

export const useCartStore = defineStore('cart', {
    state: (): { items: CartItem[] } => ({
        items: [],
    }),
    getters: {
        getItems: (state) => state.items,
    },
    actions: {
        addItem(item: CartItem): void {
            const foundItemIndex = this.findItemIndexWithEqualProduct(item);
            if (foundItemIndex !== null) {
                this.$state.items[foundItemIndex].amount += item.amount;
                return;
            }
            this.$state.items.push(item);
        },
        removeItem(item: CartItem): CartItem | null {
            const foundItemIndex = this.findItemIndexWithEqualProduct(item);
            if (foundItemIndex !== null) {
                const removed = this.$state.items[foundItemIndex];
                this.$state.items.splice(foundItemIndex, 1);
                return removed;
            }
            return null;
        },
        findItemIndexWithEqualProduct(item: CartItem): number | null {
            for (const strIndex in this.$state.items) {
                const index = parseInt(strIndex);
                const productEqual = item.product.id === this.$state.items[index].product.id;
                const sizeEqual = item.product_size?.id === this.$state.items[index].product_size?.id;
                if (productEqual && sizeEqual) {
                    return index;
                }
            }
            return null;
        },
        clear(): void {
            this.$state.items = [];
        },
        countItemsWithProductId(id: number): number {
            let count = 0;
            this.$state.items.forEach((i: CartItem) => {
                if (i.product.id === id) {
                    count += i.amount;
                }
            });
            return count;
        },
        countItemsWithProductTypeId(id: number): number {
            let count = 0;
            this.$state.items.forEach((i: CartItem) => {
                if (i.product.product_type.id === id) {
                    count += i.amount;
                }
            });
            return count;
        }
    },
    persist: true,
});
