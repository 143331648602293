import { ProductSize } from "@/interfaces/products/responses/ProductSize";
import axios from "axios";
import { defineStore } from "pinia";

export const useProductSizesStore = defineStore('productSizes', {
  state: () => ({
    productSizes: [],
  }),
  getters: {
    getProductSizes: (state) => state.productSizes,
  },
  actions: {
    async fetchProductSizes() {
      const response = await axios.get('api/product_sizes');
      this.$state.productSizes = response.data.data;
    },
    async fetchProductSizesByProductTypeId(typeId: number) {
      const response = await axios.get('api/product_sizes');
      this.$state.productSizes = response.data.data.filter((s: ProductSize) => {
        return s.id === typeId;
      });
    }
  }
});
