import { ProductRuleOption } from "@/enums/ProductRuleOption";
import { ProductTypeRuleOption } from "@/enums/ProductTypeRuleOption";
import { Order } from "@/interfaces/orders/response/Order";
import { Product } from "@/interfaces/products/responses/Product";
import { ProductRule } from "@/interfaces/products/responses/ProductRule";
import { ProductType } from "@/interfaces/products/responses/ProductType";
import { ProductTypeRule } from "@/interfaces/products/responses/ProductTypeRule";
import { User } from "@/interfaces/users/responses/User";
import { UserDepartment } from "@/interfaces/users/responses/UserDepartment";
import { useOrdersStore } from "@/store/orders-store";
import { useProductRulesStore } from "@/store/product-rules-store";
import { useProductTypeRulesStore } from "@/store/product-type-rules-store";
import { Store } from "pinia";

export class RulesService {
  productRules: ProductRule[];
  productTypeRules: ProductTypeRule[];
  user: User;
  orders: Order[];
  cartStore: any;

  constructor(productRules: ProductRule[], productTypeRules: ProductTypeRule[], user: User, orders: Order[], cartStore: Store) {
    this.productRules = productRules;
    this.productTypeRules = productTypeRules;
    this.user = user;
    this.orders = orders;
    this.cartStore = cartStore;
  }

  static async init(user: User, cartStore: any): Promise<RulesService> {
    const productRulesStore = useProductRulesStore();
    const productTypeRulesStore = useProductTypeRulesStore();
    const ordersStore = useOrdersStore();

    await productRulesStore.fetchRules();
    await productTypeRulesStore.fetchRules();
    await ordersStore.fetchOrdersByUserId(user.id);

    return new RulesService(productRulesStore.getRules, productTypeRulesStore.getRules, user, ordersStore.getOrders, cartStore);
  }

  findProductRule(product: Product, userDepartment: UserDepartment, ruleOption: ProductRuleOption): ProductRule | undefined {
    return this.productRules.find(rule => {
      return rule.product.id === product.id && rule.user_department.id === userDepartment.id && rule.rule_option === ruleOption;
    });
  }

  findProductTypeRule(productType: ProductType, userDepartment: UserDepartment, ruleOption: ProductTypeRuleOption): ProductTypeRule | undefined {
    return this.productTypeRules.find(rule => {
      return rule.product_type.id === productType.id && rule.user_department.id === userDepartment.id && rule.rule_option === ruleOption;
    });
  }

  getMaxAmountRestriction(value: Product | ProductType): number | null | undefined {
    if ((<Product>value).product_type) {
      return this.findProductRule(value as Product, this.user.user_department, ProductRuleOption.MAX_AMOUNT)?.value;
    } else {
      return this.findProductTypeRule(value as ProductType, this.user.user_department, ProductTypeRuleOption.MAX_AMOUNT)?.value;
    }
  }

  getOrderInterval(product: Product): number | null {

    let rule: ProductRule | ProductTypeRule | undefined = this.findProductRule(product, this.user.user_department, ProductRuleOption.ORDER_INTERVAL);
    if (!rule) {
      rule = this.findProductTypeRule(product.product_type, this.user.user_department, ProductTypeRuleOption.ORDER_INTERVAL);
    }
    if (rule) {
      // ProductRule.value in never null when ProductRuleOption is ORDER_INTERVAL
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return rule.value!;
    }
    return null;
  }

  getMaxAddAmount(product: Product): number {
    let maxAddAmount = 100;
    const orderInterval = this.getOrderInterval(product);
    const maxAmountRestriction = this.getMaxAmountRestriction(product);
    const maxAmountRestrictionType = this.getMaxAmountRestriction(product.product_type);
    let orderedAmount = 0;
    if (maxAmountRestrictionType) {
      if (orderInterval) {
        orderedAmount = this.getOrderedAmountInInterval(product.product_type, orderInterval);
      }
      const cartItemCount = this.cartStore.countItemsWithProductTypeId(product.product_type.id);
      maxAddAmount = maxAmountRestrictionType - (cartItemCount + orderedAmount);
    }
    if (maxAmountRestriction) {
      if (orderInterval) {
        orderedAmount = this.getOrderedAmountInInterval(product, orderInterval);
      }
      const cartItemCount = this.cartStore.countItemsWithProductId(product.id);
      if (maxAmountRestrictionType) {
        if (maxAmountRestriction < maxAmountRestrictionType) {
          maxAddAmount = maxAmountRestriction - (cartItemCount + orderedAmount);
        }
      } else {
        maxAddAmount = maxAmountRestriction - (cartItemCount + orderedAmount);
      }
    }
    return maxAddAmount;
  }

  private getOrderedAmountInInterval(value: Product | ProductType, interval: number): number {
    let amount = 0;
    if ((<Product>value).product_type) {
      const filteredOrders = this.orders.filter(o => {
        const orderIncludesProduct = o.order_items.map(i => i.product.id).includes(value.id);
        const orderDateIsInInterval = o.created_at.getFullYear() >= new Date().getFullYear() - (interval - 1);
        return orderIncludesProduct && orderDateIsInInterval;
      });
      for (const order of filteredOrders) {
        for (const item of order.order_items) {
          if (item.product.id === value.id) {
            amount += item.amount;
          }
        }
      }
    } else {
      const filteredOrders = this.orders.filter(o => {
        const orderIncludesProductType = o.order_items.map(i => i.product.product_type.id).includes(value.id);
        const orderDateIsInInterval = o.created_at.getFullYear() >= new Date().getFullYear() - (interval - 1);
        return orderIncludesProductType && orderDateIsInInterval;
      });
      for (const order of filteredOrders) {
        for (const item of order.order_items) {
          if (item.product.product_type.id === value.id) {
            amount += item.amount;
          }
        }
      }
    }
    return amount;
  }

  getProductRestriction(product: Product): string | null {
    let restriction = null;
    const orderInterval = this.getOrderInterval(product);
    const maxAmountRestriction = this.getMaxAmountRestriction(product);
    const maxAmountRestrictionType = this.getMaxAmountRestriction(product.product_type);

    let orderIntervalStringSection = `alle ${orderInterval} Jahre`;
    if (orderInterval === 1) {
      orderIntervalStringSection = "pro Jahr";
    }

    let orderedAmount = 0;
    if (maxAmountRestrictionType) {
      if (orderInterval) {
        orderedAmount = this.getOrderedAmountInInterval(product.product_type, orderInterval);
      }
      const cartItemCount = this.cartStore.countItemsWithProductTypeId(product.product_type.id);
      if (cartItemCount + orderedAmount >= maxAmountRestrictionType) {
        if (maxAmountRestrictionType === 1) {
          restriction = `Von diesem Produkttypen kann nur ${maxAmountRestrictionType} ${orderIntervalStringSection} bestellt werden.`;
        } else {
          restriction = `Von diesem Produkttypen können nur ${maxAmountRestrictionType} ${orderIntervalStringSection} bestellt werden.`;
        }
      }
    }
    if (maxAmountRestriction) {
      if (orderInterval) {
        orderedAmount = this.getOrderedAmountInInterval(product, orderInterval);
      }
      const cartItemCount = this.cartStore.countItemsWithProductId(product.id);
      if (cartItemCount + orderedAmount >= maxAmountRestriction) {
        if (maxAmountRestrictionType) {
          if (maxAmountRestriction < maxAmountRestrictionType) {
            if (maxAmountRestriction === 1) {
              restriction = `Von diesem Produkt kann nur ${maxAmountRestriction} ${orderIntervalStringSection} bestellt werden.`;
            } else {
              restriction = `Von diesem Produkt können nur ${maxAmountRestriction} ${orderIntervalStringSection} bestellt werden.`;
            }
          }
        } else {
          if (maxAmountRestriction === 1) {
            restriction = `Von diesem Produkt kann nur ${maxAmountRestriction} ${orderIntervalStringSection} bestellt werden.`;
          } else {
            restriction = `Von diesem Produkt können nur ${maxAmountRestriction} ${orderIntervalStringSection} bestellt werden.`;
          }
        }
      }
    }
    return restriction;
  }
} 
