import { defineStore } from "pinia";
import axios from "axios";
import { Order } from "@/interfaces/orders/response/Order";
import { mapDate } from "@/helpers";
import { OrderItem } from "@/interfaces/orders/response/OrderItem";

export const useOrdersStore = defineStore('orders', {
    state: () => ({
        orders: [] as Order[],
    }),
    getters: {
        getOrders: (state) => mapDate(state.orders),
    },
    actions: {
        async fetchOrdersByUserId(userId?: number): Promise<void> {
            const response = await axios.get('api/orders/user/' + userId);
            response.data.data.map((o: Order) => {
                o.order_items.map((i: OrderItem) => {
                    i.delivery_date = i.delivery_date ? new Date(i.delivery_date) : null;
                });
            });
            this.$state.orders = response.data.data;
        },

        async fetchOrders(): Promise<void> {
            const response = await axios.get('api/orders');
            response.data.data.map((o: Order) => {
                o.order_items.map((i: OrderItem) => {
                    i.delivery_date = i.delivery_date ? new Date(i.delivery_date) : null;
                });
            });
            this.$state.orders = response.data.data;
        },

        async updateOrderWithId(orderId: number): Promise<void> {
            const response = await axios.get('api/orders/' + orderId);
            response.data.data.order_items.map((i: OrderItem) => {
                i.delivery_date = i.delivery_date ? new Date(i.delivery_date) : null;
            });
            for (const i in this.$state.orders) {
                if (this.$state.orders[i].id === response.data.data.id) {
                    this.$state.orders[i] = response.data.data;
                }
            }
        },

        removeOrder(item: Order): void {
            const index = this.$state.orders.findIndex((i: Order) => i.id === item.id);
            if (index !== -1) {
                this.$state.orders.splice(index, 1);
            }
        },
    }
});
