import { OrderItem } from "@/interfaces/orders/response/OrderItem";
import axios from "axios";
import { defineStore } from "pinia";

export const useOrderStore = defineStore('order', {
  state: () => ({
    order: null,
  }),
  getters: {
    getOrder: (state) => state.order,
  },
  actions: {
    async fetchOrder(id: string) {
      const response = await axios.get(
        'api/orders/' + id,
      );
      response.data.data.order_items.map((i: OrderItem) => {
        i.delivery_date = i.delivery_date ? new Date(i.delivery_date) : null;
      });
      this.$state.order = response.data.data;
    },
  },
});
