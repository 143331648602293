export enum OrderItemStatus {
  ORDERED = '1',
  DELIVERY_DELAY = '2',
  NOT_AVAILABLE = '3',
  SUBSTITUTE = '4',
  MISSING = '5',
  IN_PRINT = '6',
  DELIVERED = '7',
  READY_TO_OUTPUT = '8',
  DONE = '9',
  OPEN = '10',
}
