import {defineStore} from "pinia";
import axios from "axios";

export const useRolesStore = defineStore('roles', {
    state: () => ({
        roles: [],
    }),
    getters: {
        getRoles: (state) => state.roles,
    },
    actions: {
        async fetchRoles() {
            const response = await axios.get('api/roles');
            this.$state.roles = response.data.data;
        }
    },
});
